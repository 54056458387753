@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "./variables.scss";

@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/utilities";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/close";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/custom-forms";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/spinners";
@import "~bootstrap/scss/transitions";
@import "~bootstrap/scss/modal";
@import "~bootstrap/scss/carousel";
@import "~bootstrap/scss/input-group";
@import "~bootstrap/scss/dropdown";

@import './common.scss';
@import './common_components.scss';
@import './home/home.scss';
@import './offers/offer.scss';
@import './stores/store_details.scss';
@import './carousel/carousel.scss';
@import './carousel/slick-carousal.scss';

html {
  font-size: 16px!important;
  @media screen and (max-width: 576px) {
    font-size: 14px!important;
  }
  @media screen and (max-width: 450px) {
    font-size: 13px!important;
  }
  @media screen and (max-width: 360px) {
    font-size: 12px!important;
  }
  @media screen and (max-width: 330px) {
    font-size: 11px!important;
  }
}

body {
  background-color: $light;
}

a {
  color: $primary;
}

input, .form-control {
  outline: none!important;
  color: $black;
}

input:focus, .form-control:focus {
  border-color: $primary-50;
  // box-shadow: 0 0 0 0.1rem $primary-50;
  box-shadow: none;
}

.container {
  @media screen and (max-width: 1200px) {
    margin-left: 0px;
    margin-right: 0px;
    width: 100%;
    max-width: 100%;
  }
}

.btn, .form-control,
input, .input-group {
  border-radius: 2px;
}

.btn, b {
  font-weight: 700!important;
}

.font-weight-semi-black {
  font-weight: 700 !important;
}

.font-weight-black {
  font-weight: 900!important;
}

.cursor-pointer, .btn {
  cursor: pointer;
}

.cursor-default {
  cursor: default;
}

.cursor-text {
  cursor: text;
}

.no-select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.opacity-disabled {
  opacity: $btn-disabled-opacity;
}

.opacity-28 {
  opacity: 0.28;
}

.btn.disabled, .btn:disabled {
  opacity: 1;
  background-color: $disabled;
  color: $white;
  border: $disabled;
  box-shadow: none;
}

@each $color, $value in $theme-colors {
  .btn-outline-#{$color} {
    &.disabled,
    &:disabled {
      color: $disabled !important;
      border: $btn-border-width $disabled solid !important;
      background-color: transparent;
    }
  }
}

a, a:hover, .btn-link, .btn-link:hover, .btn-link:not(:disabled):not(.disabled):active,
.btn-link:not(:disabled):not(.disabled).active, .show > .btn-link.dropdown-toggle,
.btn-link:not(:disabled):not(.disabled):active:focus, .btn-link:not(:disabled):not(.disabled).active:focus,
.btn-link.dropdown-toggle:focus, .btn-link:focus {
  color: $primary;
  background-color: transparent;
  border-color: transparent;
  text-decoration: none;
  outline: none;
  box-shadow: none;
}

a.text-white:hover, a.text-white:focus {
  color: $white !important;
}

a.text-dark:hover, a.bg-white:hover {
  background-color: transparent !important;
}

.btn-link:disabled, .btn-link.disabled {
  color: $secondary;
  background-color: transparent;
  border-color: transparent;
  pointer-events: none;
}

body, .btn {
  font-size: 1rem;
}

.fs-01 {
  font-size: 28px!important;
  @media screen and (max-width: 330px) {
    font-size: 2rem!important;
  }
}

.fs-1, h1 {
  font-size: 24px!important;
  @media screen and (max-width: 330px) {
    font-size: 1.5rem!important;
  }
}

.fs-2, h2 {
  font-size: 22px!important;
  @media screen and (max-width: 330px) {
    font-size: 1.4rem!important;
  }
}

.fs-3, h3 {
  font-size: 18px!important;
  @media screen and (max-width: 330px) {
    font-size: 1.2rem!important;
  }
}

.fs-4, h4 {
  font-size: 16px!important;
  @media screen and (max-width: 330px) {
    font-size: 1rem!important;
  }
}

.fs-5, h5 {
  font-size: 14px!important;
  @media screen and (max-width: 330px) {
    font-size: 0.9rem!important;
  }
}

.fs-6, h6 {
  font-size: 12px!important;
  @media screen and (max-width: 330px) {
    font-size: 0.8rem!important;
  }
}

.fs-7 {
  font-size: 10px!important;
  @media screen and (max-width: 330px) {
    font-size: 0.7rem!important;
  }
}

.fs-8 {
  font-size: 8px!important;
  @media screen and (max-width: 330px) {
    font-size: 0.6rem!important;
  }
}

*::-webkit-scrollbar {
  @media screen and (min-width: 992px) {
    width: 10px;
    height: 5px;
  }
}

*::-webkit-scrollbar:horizontal {
  display: none;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background: $grey-light;
}

* {
  scrollbar-width: none;
  scroll-behavior: auto;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
}

.text-decoration-underline {
  text-decoration: underline;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: $danger;
  padding-right: inherit;
  background: none;
}

.custom-control-label::before {
  width: 1.15rem;
  height: 1.15rem;
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: $white;
  border-color: $primary;
  background-color: $white;
}

.custom-control-label::after {
  top: 0.244rem;
  left: -1.4756rem;
  width: 1.15rem;
  height: 1.15rem;
}

.sell-on-lovelocal-button {
  border-radius: 20px;
}

.firstNameButton {
  max-width: 150px;
}

.border-radius-25p {
  border-radius: 25px !important;
}

.border-radius-4 {
  border-radius: 4px !important;
}

.border-radius-3 {
  border-radius: 3px;
}

.border-radius-8 {
  border-radius: 8px;
}

.border-height-4 {
  height: 4px !important;
}

.border-radius-16 {
  border-radius: 16px;
}

.spinner {
  height: 1.2rem !important;
  width: 1.2rem !important;
}

.shopLogo {
  height: 100px!important;
  width: 100px!important;
  @media screen and (min-width: 1200px) {
    height: 100px!important;
    width: 100px!important;
  }
  @media screen and (min-width: 992px) {
    height: 140px!important;
    width: 140px!important;
  }
  @media screen and (min-width: 768px) {
    height: 120px!important;
    width: 120px!important;
  }
  @media screen and (min-width: 411px) {
    height: 100px!important;
    width: 100px!important;
  }
  @media screen and (max-width: 411px) {
    height: 60px!important;
    width: 60px!important;
  }
}

.text-truncate-2 {
  height: calc(1.4rem * 2) !important;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  @media screen and (max-width: 567px) {
    height: calc(1.4rem * 2.5) !important;
  }
}

.text-truncate-1 {
  height: calc(1.4rem * 1) !important;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  @media screen and (max-width: 567px) {
    height: calc(1.4rem * 1.2) !important;
  }
}

.text-truncate-1.fs-6 {
  height: 16px !important;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  @media screen and (max-width: 370px) {
    height: calc(1.4rem * 1.5) !important;
  }
}

.text-truncate-1.fs-7 {
  height: calc(1rem * 1) !important;
}

.text-truncate-2.fs-6 {
  height: calc(1.1rem * 2.25) !important;
  @media screen and (max-width: 370px) {
    height: calc(1.4rem * 2.3) !important;
  }
}

.text-truncate-2.fs-5 {
  height: calc(14px * 3) !important;
  // @media screen and (min-width: 576px){
  //   height: calc(1.1rem * 3) !important;
  // }
}

.past-product-save-tag {
  position: absolute;
  top: -7px;
  left: -7px;
}

.past-product-badge-text {
  position: relative;
  left: 4px;
  width: 30px;
  line-height: normal;
  white-space: break-spaces;
}

.past-product-badge-text.small {
  left: 2px;
  width: 16px;
}

.r__badge_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
}

.r__badge_svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}


.text-truncate-22 {
  height: calc(1.4rem * 2) !important;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  @media screen and (max-width: 411px) {
    height: calc(3.8rem) !important;
  }
}

.related-product {
  max-width: 2.75%;
  @media screen and (max-width: 411px) {
    max-width: 9% !important;
  }
}

.variant-product {
  border: none;
  outline: none;
  background-color: #FFFFFF;
  font-weight: bold;
  color: #414E5A;
}

.tick-icon {
  position: absolute;
  top: -9px;
  left: 12px;
}

#global-value-strip {
  border-radius: 16px;
  overflow: hidden;
  box-shadow: 0px 0px 9px 2px rgba(43, 43, 160, 0.25);
}

#customer-support-value-strip {
  position: absolute;
  bottom: 24px;
  right: 24px;
  z-index: 100;
  width: 354px;
  gap: 8px;
  display: flex;
  flex-direction: column-reverse;
  opacity: 1;
  transition: opacity 0.5s ease;
  @media screen and (max-width: 768px) { 
    bottom: 0px;
    right: 8px;
    left: 8px;
    width: calc(100% - 16px);
  }
}

.hidden {
  opacity: 0 !important; 
}
