.h-38p {
  height: 38px;
}

.h-40p {
  height: 40px;
}

.h-70p {
  height: 70px;
}

.h-110p {
  height: 110px;
}

.h-118p {
  height: 118px;
}

.h-174p {
  height: 174px;
}

.h-60p {
  height: 60px;
}

.h-76p {
  height: 76px;
}

.w-25p {
  width: 25px;
}

.w-40p {
  width: 40px;
}

.w-180p {
  width: 200px;
}

.mw-120p {
  max-width: 120px;
}

.mw-150p {
  max-width: 150px;
}

.mw-200p {
  max-width: 200px;
}

.mw-250p {
  max-width: 250px;
}

.mw-300p {
  max-width: 300px;
}

.mw-320p {
  max-width: 320px;
}

.mw-400p {
  max-width: 400px;
}

.min-w-70p {
  min-width: 70px;
}

.pb-40p {
  padding-bottom: 40px;
}

.pb-20p {
  padding-bottom: 20px;
}

.mb-56p {
  margin-bottom: 56px;
}

.pt-56p {
  padding-top: 56px;
}

.pt-76p {
  padding-top: 76px;
}

.top-110p {
  top: 110px;
}

.top-174p {
  top: 174px;
}

.top-70p {
  top: 70px;
}

.top-118p {
  top: 118px;
}

.top-76p {
  top: 76px;
}

.zig-zag {
  background: url('/images/zigzag.svg');
  background-repeat: repeat-x;
  background-size: 18px 13px;
  display: block;
  width: 100%;
  height: 13px;
  margin-bottom: -1px;

  @media screen and (min-width: 992px) {
    background-size: 22px 16px;
    height: 16px;
  }
}

.zig-zag.bottom {
  transform: rotate(180deg);
  margin-top: -1px;
}

.overflow {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-x {
  overflow-x: scroll;
}

.pointer-event-none {
  pointer-events: none;
}

.main-body {
  background-color: $light;
  user-select: none;
  cursor: default;
}

.custom-input {
  border-top: none;
  border-right: none;
  border-left: none;
}

.custom-input:focus {
  box-shadow: none !important;
  border-color: $primary;
  border-width: 2px;
}

.text-underline {
  text-decoration: underline;
}

.line-height-24p {
  line-height: 24px;
}

.height-fit-contain {
  height: fit-content;
}

.list-section {
  height: 100%;
  min-height: auto !important;
  overflow-y: scroll;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  position: relative;
}

.overflow-y-scroll {
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

.overflow-y-with-scrollbar {
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

.overflow-y-with-scrollbar::-webkit-scrollbar {
  width: 4px;
  height: 0px;
}

.dotted-border {
  border: dotted;
  border-width: 2px;
  border-color: $disabled;
}

.dotted-border-right {
  border-right: dotted;
  border-width: 2px;
  border-color: $disabled;
}

.dotted-border-top {
  border-top: dotted;
  border-width: 2px;
  border-color: $disabled;
}

.dotted-border-bottom {
  border-bottom: dotted;
  border-width: 2px;
  border-color: $disabled;
}

.side-overlay {
  overflow-x: hidden;
  transition: all 0.5s ease-out;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  position: fixed;
  z-index: 1038;
  right: 0;
  width:  420px;
  height: 100%;
  // height: calc(100% - 76px);
  // top: 76px;

  @media screen and (max-width: 992px) {
    bottom: 0;
    width: 100%;
    height: auto;
    top: unset;
    left: 0;
  }
}

.side-overlay-flexible {
  height: auto !important;
  max-height: 80% !important;
}

.side-overlay-new {
  width:430px !important;
}

.hide-side-overlay {
  right: -100vw !important;

  @media screen and (max-width: 992px) {
    bottom: -100vh !important;
  }
}

.overlay-list {
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  max-height: calc(100% - 68px);

  @media screen and (max-width: 576px) {
    max-height: calc(100% - 60px);
  }

  @media screen and (max-width: 450px) {
    max-height: calc(100% - 56px);
  }

  @media screen and (max-width: 360px) {
    max-height: calc(100% - 52px);
  }

  @media screen and (max-width: 330px) {
    max-height: calc(100% - 48px);
  }
}

.max-h-100 {
  max-height: 100%;
}

.rotate-180 {
  transform: rotate(180deg);
  transition: all 0.2s ease-out;
  -webkit-transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  -ms-transition: all 0.2s ease-out;
}

.border-bottom-primary {
  border-bottom: 2px solid $primary !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.horizontal-scroll-list {
  overflow-y: hidden;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
  white-space: nowrap;
  scroll-behavior: smooth;
}

.horizontal-scroll-list::-webkit-scrollbar {
  width: 0 !important;
}

.grey-border {
  border: solid;
  border-width: 2px;
  border-color: $grey-light !important;
  border-radius: 4px;
}

.checkout-product-section::-webkit-scrollbar,
.reorder-product-section::-webkit-scrollbar .location-search .autocomplete-dropdown-container::-webkit-scrollbar {
  width: 4px;
  height: 0px;
}

.fixed-top {
  position: absolute;
  width: 100%;
}

.fixed-bottom {
  position: absolute;
  width: 100%;
}

.bottom-menu {
  box-shadow: 0 0 6px rgba($black, 0.25) !important;
}

.main-content-section {
  position: absolute;
  margin-top: -2px;
  width: 100%;
  // top: 56px;
  // top: 90px;
  bottom: 0px;
  overflow: hidden;

  @media screen and (min-width: 992px) {
    bottom: 0px;
    // top: 76px;
  }
}

.main-content-b2bstorepreview {
  top: 0 !important;
}

.main-content-section.show-bottom-menu {
  bottom: 56px;
}

.customer-support {
  align-self: flex-end;
}

.customer-support-overlay {
  position: absolute;
  bottom: calc(10% + 4rem);
  right: 6%;
}

.z-100 {
  z-index: 100;
}

#custom-scroller #prev-button-section {
  position: absolute;
  // height: 100%;
  align-self: center;
  z-index: 100;
  left: 0;
  display: none;

  @media screen and (min-width: 992px) {
    display: block;
  }
}

#custom-scroller #next-button-section {
  position: absolute;
  // height: 100%;
  align-self: center;
  z-index: 100;
  right: 0;
  display: none;

  @media screen and (min-width: 992px) {
    display: block;
  }
}

.r__container #custom-scroller {
  position: relative;
}
.r__container #custom-scroller #prev-button-section {
  height: max-content !important;
  top: 50%;
  transform: translateY(-50%);
}

.r__container #custom-scroller #next-button-section {
  height: max-content !important;
  top: 50%;
  transform: translateY(-50%);
}


.h-48p {
  height: 48px;
}

.scratch-card {
  background: url('/images/scratch-card-now.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.scratched-card {
  background: url('/images/scratched-card.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.scratch-card-not-available {
  background: url('/images/scratch-card-not-available.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.toast-popup {
  position: fixed;
  bottom: 5rem;
  z-index: 1051;
  width: 100vw;
  left: 0;
  right: 0;
}

.red-dot-top {
  padding: 0.25rem 0.25rem;
  border-radius: 50%;
  margin-left: 4px;
}

.vh-90 {
  height: 90vh !important;
}

.filter-grayscale {
  filter: grayscale(100%) !important;
}

.refer-earn-image {
  width: 100%;
  height: 200px;
  object-fit: contain;

  @media screen and (max-width: 992px) {
    height: 186px;
  }

  @media screen and (max-width: 768px) {
    height: 160px;
  }

  @media screen and (max-width: 576px) {
    height: 136px;
  }

  @media screen and (max-width: 448px) {
    height: 114px;
  }
}

.width240p {
  width: 240px !important;
}


.nav-categories {
  position: relative;
}

.nav-categories:hover {
  .nav-categories-primary {
    display: block;
  }
}

.nav-categories-primary {
  position: absolute;
  display: none;
  top: 44px;
  z-index: 1051;
  left: 0;
}

.search-suggestions {
  position: absolute;
  top: 50px;
  z-index: 1100;
  left: 0px;
  width: 120% !important;
  @media only screen and (max-width: 992px) {
    top: 43px;
    width: 105% !important;
    left: -8px;
  }
}

.shop-by-category {
  background-color: $white;
  max-height: 500px;
  height: 100%;
  overflow-y: scroll;
  overflow-x: visible;
}

.nav-sub-category {
  display: none;
  position: absolute;
  z-index: 1051;
  left: 200px;
  top: 0px;
  height: 500px;
  overflow-y: scroll;

  .btn {
    width: 200px;

    &:hover {
      background-color: $white !important;
      color: $primary !important;

      .sub-category-text {
        font-weight: 700 !important;
      }
    }
  }
}

.nav-category {
  &:hover {
    background-color: $pink-pale !important;

    .nav-sub-category {
      display: block !important;
      background-color: $white !important;
    }
  }
}

.nav-category>a .btn {
  width: 200px;

  &:hover {
    color: $primary !important;

    .category-text {
      font-weight: 700 !important;
    }
  }
}

.top-nav-category-left {
  position: relative;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
  padding: 12px;
}

.top-nav-category-right {
  position: relative;
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
  padding: 10px;
}

.global-search-input {
  height: 44px !important;
  // max-width: 450px;
}

.nav-sell-on-ll {
  display: none;

  @media only screen and (min-width: 1350px) {
    display: block;
  }
}

.link-tag {
  text-decoration: none !important;
  color: inherit !important;
}

.custom-badge-text {
  position: absolute;
  top: 4px;
  left: 5px;
  width: 62px;
  text-align: center !important;
}

.smooth-scroll {
  scroll-behavior: smooth;
}

.mSite-top-menu-logo {
  @media screen and (max-width: 340px) {
    display: none;
  }
}

.dropdown-toggle::after {
  display: none;
}

.dropdown-menu.show {
  width: 330px;
  z-index: 1050;
  top: 15px !important;
  left: 330px;
}

.address-dropdown .dropdown-menu.show {
  border: none;
  width: 10px;
  top: -15px !important;
}

.word-break {
  word-break: break-word;
}

.hero-banner {
  // background-color: #a1278e;
  background-image: url('/images/hero-background.jpg');
}

.px-lg-global-search {
  @media only screen and (min-width: 1400px) {
    padding-left: 5rem;
    padding-right: 5rem;
  }

  @media only screen and (min-width: 1500px) {
    padding-left: 7rem;
    padding-right: 7rem;
  }

  @media only screen and (min-width: 1600px) {
    padding-left: 8rem;
    padding-right: 8rem;
  }

  @media only screen and (min-width: 1800px) {
    padding-left: 10rem;
    padding-right: 10rem;
    width: 1800px;
  }
}

.search-text-suggestions {
  background-color: $white;
  max-height: 512px;
  height: auto;
  overflow-y: scroll;
  overflow-x: visible;
  color: black;
  @media only screen and (max-width: 992px) {
    max-height: 375px;
  }
}

.category-filter {
  background-color: $white;
  max-height: 600px;
  height: 100%;
  overflow-y: scroll;
  overflow-x: visible;
}

.variant-selection-list {
  max-height: 500px;
  overflow-y: scroll;
  overflow-x: visible;
}

.blogLinks {
  padding: 5px 10px;
  font-size: 12px;
  margin: 7.5px;
  background: #fff;
  border-radius: 8px;
  cursor: pointer;
}

//Revamped classes with r__ prefix

$xxs: 360px;
$xs: 460px;
$sm: 580px;
$md: 767px;
$lg: 1170px;
$xl: 1366px;

.r__flex {
  display: flex;
}

.r__flex--linear {
  width: max-content;
}

.r__flex-wrap {
  flex-wrap: wrap;
}

.r__flex-5 {
  gap: 5px;
}

.r__flex-10 {
  gap: 10px;
}

.r__flex-16 {
  gap: 16px;
}

.r__flex-20 {
  gap: 20px;
}

.r__flex-24 {
  gap: 24px;
}

.r__flex-28 {
  gap: 20px;
}

.r__flex-30 {
  gap: 30px;
}

.r__flex-55 {
  gap: 55px;
}

.r__container {
  max-width: 1280px;
  margin: 0 auto;
  padding-left: 15px;

  @media screen and (min-width: $md) {
    padding-left: 35px;
  }

  @media screen and (min-width: $lg) {
    padding-left: 55px;
  }
}

.r__section {
  position: relative;
  margin-top: 40px;
  padding-right: 15px;

  @media screen and (min-width: $md) {
    padding-right: 35px;
  }

  @media screen and (min-width: $lg) {
    padding-right: 55px;
  }

  @media screen and (min-width: $xl) {
    padding-right: 0;
  }
}

.r__section_first {
  margin-top: 15px;
}

.r__section_last {
  margin-bottom: 67px;
}

.r__mt-17 {
  margin-top: 68px;
}

.recent-search-suggestion {
  position: absolute;
  top: 50px;
  z-index: 1;
  left: 0px;
  width: 537px !important;
  @media only screen and (max-width: 992px) {
    top: 43px;
    width: 105% !important;
    left: -8px;
  }
}

.popular-brand-container {
  height: 96px;
  width: 96px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.w-96p {
  width: 96px;
}
.w-33 {
  width: 33% !important;
}

.address-action:hover {
  background-color: #FFF6FD;
}

.align-self-center {
  align-self: center;
}

.r__location_selector{
  display: flex;
  
}

.r__acc_drop .dropdown-menu.show {
  width: 375px;
  z-index: 1050;
  top: 20px !important;
  left: 100%;
  border: none; 
  padding: 0 !important;
  border-radius: 16px;
  box-shadow: -7px 0px 8px 0px rgba(0, 0, 0, 0.10);
  inset: 0px 0px auto auto !important;
  transform: translate3d(-0.5px, 40px, 0px) !important;
}

.gap-20 {
  gap: 20px;
}

.r__container_rest{
  padding-left: 0;
  padding-right: 0;
}

.r__btn{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  width: min-content;
  white-space: nowrap;
  padding: 12px 0;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 900;
  line-height: 16px;
  letter-spacing: 0.2px;
  text-transform: uppercase;
  cursor: pointer;
}

.r__btn--primary{
  background-color: #B8238E;
  color: #fff;
}

.r__btn--secondary{
  border: 1px solid #B8238E;
  background-color: #fff;
  color: #B8238E;
}

.r__card_wrapper_btn {
  position: relative;
  border: none;
  background: transparent;
}

.r__play_button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -70%);
  border: none;
  background: none;
}

.gap-10 {
  gap: 10px,
}

.z-index-1050 {
  z-index: 1050;
}

.address-bar:hover {
  background-color: rgba(0,0,0,.075)
}

.pt-12p{
  padding-top: 12px;
}

.pb-12p{
  padding-bottom: 12px;
}
