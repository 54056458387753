    .r__main_btn-wrapper{
        height: min-content;
    }
  
    .r__main_btn{
        padding: 0.375rem 0.75rem;
        border: none;
        background: none;
    }

    .r__acc_backdrop{
        position: absolute;
        top: 76px;
        right: 0;
        bottom: 0;
        left: 0;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.3);
    }
    
    .r__acc_container{
        height: 85vh;
        padding: 0 !important;
        @media screen and ( min-width: 992px ) {
            width: 375px;
            border-radius: 16px;
            border: 1px solid #fafafa;
        }

        &::-webkit-scrollbar {
            width: 6px;
            margin-top: 2rem;
            margin-bottom: 2rem;
        }
        &::-webkit-scrollbar-thumb {
            margin-top: 2rem;
            margin-bottom: 2rem;
        }

    }

    .r__acc_profile{
        padding: 16px;
        border-radius: 16px;
    }

    .r__acc_profile_image {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 60px;
        height: 60px;
        margin-right: 10px;
        @media screen and ( max-width: 992px ) {
            width: 40;
            height: 40;   
        }
    
        img{
            border-radius: 50%;
            border: 1px solid #f1f1f1;
        }
    }

    .r__acc_edit-pencil {
        position: absolute;
        right: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 20px;
        height: 20px;
        padding: 4px;
        border-radius: 50%;
        transform: translate(14%);
        box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.24);
        background-color: #fff;
        cursor: pointer;
    }

    .r__acc_name{
        color: #212121;
        font-size: 18px;
        font-weight: 700;
        line-height: 24px;
    }

    .r__acc_contact{
        color: #111;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
    }

    .r__acc_edit{
        color: #B8238E;
        font-size: 10px;
        font-weight: 700;
        line-height: 12px;
        letter-spacing: -0.1px;
        text-transform: uppercase;
    }

    .r__acc_row{
        border-bottom: 1px solid #e1e1e1 !important;
        padding: 0;
    }

    .r__acc_row-refer{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 134px;
        overflow: hidden;
        @media screen and ( min-width: 600px ) {
            height: 230px;
        }
        @media screen and ( min-width: 992px ) {
            height: 134px;
        }
    }

    .r__acc_row-refer--0{
        background-image: url('/images/refer_stripe.jpg');
        background-size: cover;
        background-position: center;
    }

    .r__acc_row-refer--1{
        justify-content: space-between;
        flex-direction: column;
        padding: 25px 30px 19px 30px;
        background-color: #2B2BA0 !important;
        background-image: url('/images/intersect.png');
        background-size: contain;
        background-repeat: no-repeat;
    }

    .r__acc_refer-top{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 290px;
    }

    .r__acc_refer-title{
        width: 126px;
        margin-right: 0;
        text-align: left;
        font-size: 15px;
        font-weight: 800;
        line-height: 18px;
        color: #fff;
        
        @media screen and ( min-width: 460px ) {
            margin-right: 20px;
        }
    }

    .r__acc_refer-button{
        padding: 5px 12px;
        text-align: center;
        border-radius: 8px;
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 900;
        line-height: 20px;
        background-color: #fff;
        color: #0E0E94;
    }

    .r__acc_refer-rewards{
        height: 42px;
        position: relative;
        display: flex;
        align-items: center;
    }

    .r__acc_refer-line{
        width: 288px;
        height: 2px;
        background-color: #fff;
    }

    .r__acc_refer-circles{
        position: absolute;
        top: 9px;
        display: flex;
        justify-content: space-between;
        width: 100%;
    }

    .r__acc_refer-circle{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 42px;
        color: #fff;
        text-align: center;
        font-size: 11px;
        font-weight: 900;
        line-height: 16px;
    }

    .r__acc_footer{
        display: flex;
        align-items: center;
        width: 100%;
        padding: 16px 30px;
        border-radius: 0 0 16px 16px;
        background: #F6F6F6;
    }

    .r__acc_barcode{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 115px;
        height: 115px;
        overflow: hidden;
        background-image: url(/images/qr-code.png);
        background-size: cover;
        background-position: center;
        flex-shrink: 0;
    }

    .r__acc_copy{
        margin-left: 23px;
    }

    .r__acc_copy-heading{
        font-size: 14px;
        font-weight: 900;
        line-height: 20px;
        color: #212121;

    span{
        color:#B8238E
    }
    }

    .r__acc_copy-info{
        margin-top: 8px;
        font-size: 12px;
        font-weight: 400;
        line-height: normal;
        color: #212121;
    }