$coupon-card-height: 126px;
$coupon-card-height-store-view: 100px;

.custom-border-bottom {
  border-bottom: 3px solid transparent !important;
}

.custom-border-bottom:hover,
.custom-border-bottom-primary {
  border-bottom: 3px solid $primary !important;
}

.modal-content {
  border-radius: 2px !important;
  border: none;
  background-color: transparent;
}

.modal-body {
  padding: 0;
}

.modal-header {
  align-items: flex-end;
}

.modal-header .close {
  font-size: 2rem;
  font-weight: normal;
  outline: none;
}

.backdrop {
  background-color: #66666670;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100%;
  z-index: 1031;
}

.search-bar {
  position: absolute;
  top: -24px;
  width: 100%;
  z-index: 1;
}

.error-placeholder {
  width: 100%;
  max-width: 300px;
}

.cart-counter {
  min-width: 90px;

  @media screen and (max-width: 567px) {
    min-width: 80px;
  }
}

.cart-counter-min {
  min-width: 70px;
  align-items: center;
  gap: 4px;
}

.cart-count {
  position: absolute;
  top: 30%;
  right: 0.5rem;
  padding: 0.1rem 0.2rem;
  font-weight: 700;
  font-size: 0.7rem;
  color: #b8238e;
  border-radius: 50%;
}

.desktop-cart-count {
  position: absolute;
  top: 20%;
  left: 20%;
  padding: 0.1rem 0.2rem;
  font-weight: 900;
  font-size: 8px;
  color: #b8238e;
  border-radius: 50%;
}

#online-product .product-image-div {
  width: 64px !important;
  height: 64px !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.call-close {
  position: absolute;
  top: 0;
  right: 0;
}

//Revamped classes with r__ prefix

.r__side_overlay-social {
  height: auto;
}

// SECTION TITLE BUTTON
.r__title_link-btn {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 900;
  text-transform: capitalize !important;
  border: none;
  background: none;
  color: #b8238e;
}

// LODER
.r__loader {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

// REFER CARD
.r__refer_card {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 320px;
  height: 126px;
  border-radius: 16px;
  overflow: hidden;
  cursor: pointer;

  img {
    width: 100%;
    height: 100%;
  }
}

// SCRATCH CARD
.r__scratch_card {
  width: 320px;
  height: 126px;
  padding: 19px 20px;
  border-radius: 16px;
  background-image: url('/images/scratch-card-bg.png');
  background-size: contain;
  background-color: #2B2BA0;
  border: none;
}

.r__scratch_card-title {
  max-width: 149px;
  white-space: break-spaces;
  font-weight: 900;
  font-size: 18px;
  line-height: 22px;
  text-transform: capitalize;
  text-align: left;
  color: #fff;
}

.r__scratch_card-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 145px;
  height: 30px;
  margin-top: 14px;
  border-radius: 8px;
  font-weight: 900;
  font-size: 12px;
  line-height: 20px;
  text-transform: uppercase;
  background: #FFFFFF;
  color: #2B2BA0 !important;
}


// Partial Spend/Value Offer Card 

.r__offer_card-image{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
  overflow: hidden;
  background-image: url(/images/value-offer-bg.png);
  background-size: cover;
  background-position: center;
  text-align: center;
  white-space: break-spaces;
  font-size: 20px;
  font-style: italic;
  font-weight: 900;
  line-height: 98%;
  text-transform: uppercase;
  color: #fff;
  padding: 0 6px;
}

.r__offer_card-image.disabled {
  background-image: url(/images/value-offer--disabled-bg.png);
}

.r__offer_card-label{
  position: absolute;
  top: 6px;
  left: -18px;
  transform: rotate(-45deg);
  padding: 0px 24px;
  font-size: 8px;
  font-weight: 700;
  line-height: 16px;
  background: linear-gradient(88deg, #007922 0%, #00B633 100%);
  color: #fff;
}

.r__offer_card-label--offerspage{
    position: absolute;
    bottom: 6px;
    left: 0;
    right: 0;
    padding: 4px 12px;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 800;
    line-height: 16px;
    border-radius: 4px;
    text-transform: uppercase;
    background: #005317;
    color: #fff;
}

.r__offer_card-title {
  max-width: 210px;
  max-height: 62px;
  margin-bottom: 12px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: break-spaces;
  overflow: hidden;
  text-align: left;
  font-size: 16px;
  font-weight: 900;
  line-height: 20px;
  color: #005317;
}

.r__offer_card-title.shop_front {
  -webkit-line-clamp: 2;
}

.r__offer_card-title.disabled {
  color: black;
  max-width: 250px;
}

// COUPON CARD
.r__coupon_card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 320px;
  padding: 0;
  border: none;
  background: transparent;
}

.r__coupon_card-left {
  display: flex;
  align-items: center;
  height: $coupon-card-height;
  width: 44px;
  padding: 12px;
  border-radius: 16px 0 0 16px;
  border: 1px solid #005317;
  border-radius: none;
  background: #005317;
}

.r__coupon_card-left.isStoreView {
  height: $coupon-card-height-store-view;
}

.r__coupon_card-offer {
  writing-mode: vertical-rl;
  -webkit-writing-mode: vertical-rl; /* Safari */
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg); /* Safari */
  text-align: center;
  overflow: hidden;
  font-weight: 900;
  font-size: 16px;
  line-height: 24px;
  white-space: break-spaces;
  display: -webkit-box;
  display: -ms-flexbox; /* Optional: for IE 10 and 11 */
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  color: #fff;
}

.r__coupon_card-barrier {
  position: relative;
  overflow: hidden;
  height: $coupon-card-height;
  width: 12px;
  left: -6px;
  padding-right: 12px;
}

.r__coupon_card-barrier.isStoreView {
  height: $coupon-card-height-store-view;
}

.r__coupon_card-circleTop {
  position: absolute;
  top: 0;
  transform: translateY(-50%);
  width: 12px;
  height: 12px;
  border-radius: 50%;
  border: 1px solid #005317;
  background: #fff;
}

.r__coupon_card-circleBottom {
  position: absolute;
  bottom: 0;
  transform: translateY(50%);
  width: 12px;
  height: 12px;
  border-radius: 50%;
  border: 1px solid #005317;
  background: #fff;
}

.r__coupon_card-right {
  display: flex;
  padding: 16px 12px 16px 8px;
  height: $coupon-card-height;
  width: 270px;
  margin-left: -6px;
  border-radius: 0 16px 16px 0;
  border: 1px solid #005317;
  border-left: none;
  align-items: center;
}

.r__coupon_card-right.isStoreView {
  height: $coupon-card-height-store-view;
  width: 316px;
}

.r__coupon_card-copy {
  width: 201px;
  // padding-right: 4px;
}

.r__coupon_card-title {
  max-width: 190px;
  height: 34px;
  margin-bottom: 6px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: break-spaces;
  overflow: hidden;
  text-align: left;
  font-size: 14px;
  font-weight: 900;
  line-height: 16.8px;
  color: #005317;
}

.r__coupon_card-sub {
  max-width: 190px;
  height: 34px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: break-spaces;
  overflow: hidden;
  text-align: left;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.2px;
}

.r__coupon_card-code {
  text-transform: uppercase;
}

.r__coupon_card-validity {
  text-transform: capitalize;
}

.r__coupon_card-logo {
  display: flex;
  margin-top: 12px;
  align-items: center;
}

.r__coupon_card-logo-text {
  font-weight: 700;
  font-size: 11px;
  line-height: 14px;
  color: #505050;
}

.r__coupon_card-btn {
  align-self: flex-start;
  height: max-content;
  font-size: 14px;
  font-weight: 900;
  color: #B8238E;
}

// ALL OFFERS CARD
.r__all_offers {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 126px;
  height: 126px;
  padding: 0 20px;
  border: 1px solid #B8238E;
  border-radius: 16px;
  font-weight: 900;
  font-size: 18px;
  line-height: 22px;
  text-transform: capitalize;
  white-space: break-spaces;
  cursor: pointer;

  span {
    min-width: 86px;
    margin-bottom: 6px;
    color: #B8238E;
  }
}

// STORE CARD
.r__store_wrapper {
  max-width: 340px;
  height: 232px;
  @media screen and (min-width: 460px) {
    max-width: 370px;
  }
}

.r__store_container {
  max-width: 340px;
  height: 232px;
  padding: 0;
  border-radius: 16px;
  border: 1px solid #F2F2F2;
  overflow: hidden;
  @media screen and (min-width: 460px) {
    max-width: 370px;
  }
}

.r__store_main {
  position: relative;
  overflow: hidden;
  background-color: #fff;
}

.r__store_card {
  display: flex;
  height: 186px;
  padding: 12px 0 0 12px;
  background: none;
  border: none;
}

.r__store_image {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
  width: 108px;
  height: 132px;
  overflow: hidden;

  img {
    width: 100%;
  }
}

.r__store_copy {
  width: 250px;
  height: 176px;
  padding: 0 16px 16px 0;
}

.r__store_share-btn {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  width: 32px;
  height: 32px;
  padding: 8px;
  border-top-right-radius: 16px;
  border-bottom-left-radius: 8px;
  background-color: rgba(239, 191, 225, 1) !important;
  border: none;
}

.r__store_title {
  max-width: 159px;
  height: 48px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: break-spaces;
  text-overflow: ellipsis;
  text-align: left;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: #000;
}

.r__store_type {
  max-width: 180px;
  height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-transform: capitalize;
  color: #788898;
}

.r__store_badges {
  max-width: 180px;
  height: 20px;
  overflow: hidden;
  display: flex;
  gap: 5px;
  margin: 8px 0;
}

.r__store_params1 {
  max-width: 210px;
  height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
  color: #414E5A;
  font-size: 14px;
}

.r__store_params-dot {
  display: inline-block;
  width: 4px;
  height: 4px;
  margin: 0 8px;
  border-radius: 50%;
  background-color: #d4d1d1;
}

.r__store_rating {
  color: #414E5A;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  font-weight: 700;
}

.r__store_hours {
  display: flex;
  align-items: center;
  gap: 5px;
  white-space: nowrap;
  color: #414E5A;
  font-weight: 700;
}

.r__store_params2 {
  display: flex;
  height: 20px;
  align-items: center;
  margin-top: 8px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #414E5A;
}

.r__store_distance {
  max-width: 80px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  white-space: break-spaces;
  overflow: hidden;
  text-overflow: ellipsis;
}

.r__store_locality {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  white-space: break-spaces;
  overflow: hidden;
  text-overflow: ellipsis;
}

.r__store_offer {
  display: flex;
  align-items: center;
  height: 44px;
  width: 370px;
  overflow: hidden;
  padding: 16px;
  background-color: #F9ED32;
  text-decoration-color: black;
}

.r__store_offer-text {
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  margin-left: 6px;
  white-space: nowrap;
}

// CATEGORY FILTER ROW
.r__category_slider {
  border-bottom: 1px solid #F0F0F0;
}

// CATEGORY FILTER THUMBNAIL
.r__category_thumb {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-bottom: 2px solid transparent;
  border: none;
  background: none;
}

.r__category_thumb.isActive {
  border-bottom: 2px solid #b8238e;
}

.r__category_thumb-image {
  overflow: hidden;
  height: 70px;
  width: 70px;
  padding: 10px;
  margin-bottom: 4px;
}

.r__category_thumb-title {
  width: 80px;
  height: 52px;
  margin: 0 auto;
  text-align: center;
  white-space: break-spaces;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-size: 15px;
  line-height: 24px;
  font-weight: 400px;
}

// OFFER BADGE

.r__custom_offer_badge {
  position: absolute;
  top: 0;
  left: 0;
}

.r__custom_offer_badge-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  font-weight: 900;
  font-size: 12px;
  line-height: 13px;
  color: #fff;
}

// refer_shop
.r__refer_shop{
  max-width: 370px;
  height: 232px;
  padding: 0;
}

.r__trending_product-measurement{
  text-transform: capitalize;
  line-height: 20px;
  font-size: 16px;
  font-weight: 700;
  color: #414E5A;
}

.r__trending_product-drop{
  width: 85%;
  border: none;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url('/images/carrot-down.svg');
  background-repeat: no-repeat;
  background-position: right center;
  text-transform: capitalize;
  line-height: 20px;
  font-size: 14px;
  font-weight: 700;
  color: #414E5A;
}

.r__brandVideoFrame{
  max-width: 992px;
  overflow: hidden;
}

.r__faq{
  display: block;
  padding: 8px;
  text-align: center;
  background: #f1f1f1;
  cursor: pointer;
  
  :hover{
    background: #f1f1f1 !important;
  }
}

.r__button_clean{
  background: transparent;
  border: none;
}

.r__localstore_cardlist{
  gap: 16px;
  justify-content: center;
  
  @media screen and (min-width: 768px) {
    justify-content: left;
  }
}

// New popup design start
.modal-content .r__action_container {
  width: 342px;
  margin: 0 auto;
  border-radius: 16px !important;
  box-shadow: 0px 2px 1px 0px #F4F4F4;
}

.modal-content .r__action_container--small {
  display: flex;
  width: auto;
  height: 176px;
  padding: 24px 32px;
  background-color: #fff;
}

.modal-content .r__action_container--mid {
  display: flex;
  width: auto;
  height: 254px;
  padding: 24px 32px;
  background-color: #fff;
}

.r__action_btn-wrapper{
  gap: 16px;
}

.modal-content .r__action_title {
  text-align: center;
  color: #000000 !important;
  font-size: 24px !important;
  font-weight: 900;
  line-height: 24px;
}

.modal-content .r__action_title--sm {
  text-align: center;
  color: #000000 !important;
  font-size: 16px !important;
  font-weight: 700;
  line-height: 24px;
}

.modal-content .r__action_copy{
  text-align: center;
  font-size: 14px !important;
  font-weight: 400;
  line-height: 20px;
}

.modal-content .r__action_cancel{
  border-radius: 10px;
  border: 1px solid #787878;
  color: #787878;
  text-transform: uppercase;
  font-size: 12px !important;

  @media only screen and (min-width:540px) {
    // width: 124px !important;
  }

  @media only screen and (min-width:768px) {
    font-size: 14px !important;
    border-radius: 16px;
  }

  &:hover{
    border: 1px solid transparent;
    color: #fff;
  }
}

.modal-content .r__action_confirm{
  border-radius: 10px;
  text-transform: uppercase;
  font-size: 12px !important;
  border: 1px solid #b8238e;

  @media only screen and (min-width:540px) {
    // width: 124px !important;
  }

  @media only screen and (min-width:768px) {
    font-size: 14px !important;
    border-radius: 16px;
  }
}

.modal-content .r__action_confirm_one{
  border-radius: 10px;
  text-transform: uppercase;
  font-size: 12px !important;
  font-weight: 900 !important;
  margin: 0 auto;
  // border: 1px solid #b8238e;

  @media only screen and (min-width:768px) {
    font-size: 18px !important;
    border-radius: 16px;
  }
}

.r__modalHeader-round{
  border-radius: 16px 16px 0 0;
}

.r__modalBody-round{
  border-radius: 0 0 16px 16px;
}

// New popup design end

.r__button_clean{
  background: transparent;
  border: none;
  padding-left: 0;
  padding-right: 0;
}

// Refer Shop Modal styling

.r_refShop_container{
  position: relative;
  max-width: 375px;
  padding: 40px 32px;
  margin: 0 auto;
  border-radius: 16px;
  background-color: #fff;
}

.r__refShop_close{
  position: absolute;
  top: 16px;
  right: 16px;
  padding: 0;
  border: none;
  background: none;
}

.r__refShop_body {
  display: flex;
  grid-gap: 12px;
  gap: 12px;
  flex-direction: column;
}

.r__refShop_title{
  font-size: 20px;
  font-weight: 700;
  line-height: normal;
}

.r__refShop_row {
  input{
    padding: 16px 12px;
    border-radius: 8px;
    border: 1px solid #e1e1e1;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    color: #A0A0A0;
  }
}

.r__refShop_button {
  width: 100%;
  padding: 12px 16px;
  border-radius: 8px;
  border: none;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  background-color: #B8238E;
  color: #fff;
}

.r__refShop_button--disabled {
  background-color: transparent !important;
  border: 1px solid #A0A0A0;
  color: #5d5858;
}

// Acknowledgement-page 
.r__ack_header{
  position: relative;
  width: 100%;
  height: 140px;
  background-color: #B8238E;
  
  @media only screen and (min-width:992px) {
    height: 200px;
  }
}

.r__ack_message{
  position: absolute;
  left: 50%;
  bottom: 0;
  z-index: 2;
  transform: translatex(-50%);
  width: 188px;
  color: var(--black-2, #282828);
  text-align: center;
  font-size: 18px;
  font-weight: 900;
  line-height: normal;
  @media only screen and (min-width:992px) {
    font-size: 24px;
  }
}

.r__ack_lottie{
  position: relative;
  z-index: 1;
  margin: 0 auto;
  width: max-content;

  svg{
    g[transform="matrix(7.070000171661377,0,0,7.070000171661377,-2040.549560546875,426.092529296875)"]{
      display: none;
    }
  }
}

.r__ack_circle{
  position: relative;
  top: 63px;
  transform: translateY(-38%);
  width: 290px;
  height: 290px;
  margin: 0 auto;
  border-radius: 50%;
  background-color: #fff;
  
  @media only screen and (min-width:992px) {
    width: 490px;
    height: 490px;
    transform: translateY(-29%);
  }
}

.r__ack_buttons{
  position: relative;
  z-index: 3;
  margin: 16px 0 40px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.r__ack_buttons{
  .r__btn{
    width: 254px;
    text-align: center;
  }
}

.r__ack_refer_banner{
  position: relative;
  display: block;
  margin: 0 auto;
  width: 320px;
  height: 180px;
  overflow: hidden;
  border-radius: 16px;
  border: none;
  background: url('/images/refer_and_earn.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  @media only screen and (min-width:992px) {
    width: 478px;
    height: 269px;
  }
}
.r__simpl_row{
  background: #F0F0F0;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
}
.free-text-center {
  display: flex;
  text-align: center;
}